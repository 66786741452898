<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { logout } from '../../../services/user.service'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  async mounted() {
      await logout()
      this.$router.push('home')
  },
  data() {
    return {
      title: "LOGOUT",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "LOGOUT",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        ...Cerrando sesión
      </div>
    </div>
  </Layout>
</template>